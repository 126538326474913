<template>
    <div>
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            {{$i18n.t('deviceLog.text1')}}
        </div>


        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '4px 15px'
            }"
            shadow="never">

            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="$i18n.t('deviceLog.text2')" name="first">
                    <communication-log ref="communicationLog"/>
                </el-tab-pane>
                <el-tab-pane :label="$i18n.t('deviceLog.text3')" name="second">
                    <online-and-offline-logs ref="onlineAndOfflineLogs"/>
                </el-tab-pane>
                <el-tab-pane :label="$i18n.t('deviceLog.text4')" name="third">
                    <binding-log ref="bindingLog"/>
                </el-tab-pane>
<!--                <el-tab-pane label="网络配置日志" name="fourth">-->
<!--                    <network-configuration-log ref="networkConfigurationLog"/>-->
<!--                </el-tab-pane>-->
            </el-tabs>

        </el-card>
        <div style="height: 1px"></div>
    </div>
</template>

<script>
import CommunicationLog from "@/views/maintain/components/deviceLogTabs/communicationLog";
import OnlineAndOfflineLogs from "@/views/maintain/components/deviceLogTabs/onlineAndOfflineLogs";
import BindingLog from "@/views/maintain/components/deviceLogTabs/bindingLog";
import NetworkConfigurationLog from "@/views/maintain/components/deviceLogTabs/networkConfigurationLog";
export default {

    name: "deviceLog",

    components: {NetworkConfigurationLog, BindingLog, OnlineAndOfflineLogs, CommunicationLog},

    data() {

        return {

            activeName: "first",

            cardHeight: 300

        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
    },

    methods: {

        handleClick(tab, event) {
            this.setHeight();
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 153 - 8;
            this.$refs.communicationLog.setHeight();
            this.$refs.onlineAndOfflineLogs.setHeight();
            this.$refs.bindingLog.setHeight();
            this.$refs.networkConfigurationLog.setHeight();
        }

    }

}
</script>

<style scoped>
.el-card {
    border: none;
    border-radius: 8px;
    margin: 64px 24px 24px 24px;
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

</style>
